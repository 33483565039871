<template>
  <GoodsDeliveries :is-clean-up-warehouse="true" />
</template>
<script>
import GoodsDeliveries from "./GoodsDeliveries.vue";

export default {
  components: {
    GoodsDeliveries,
  },
};
</script>
